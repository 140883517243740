@import '../../shared/settings';

.modal-content {
    position: relative;
    outline: none;
    border-radius: 5px;
    padding: 0;
    pointer-events: auto;

    .btn-close {
        position: absolute;
        top: 22px;
        right: var(--gutter-size);
    }

    .grid-page {
        padding: 0;
        grid-template-columns: 1fr;
        border-bottom: 1px solid var(--border);
        border-radius: 0;
    }

    .grid-block {
        border-radius: 0;
    }

    .grid-buttons {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    @include respond-to(mobile) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        border-radius: 0;
    }

    fieldset {
        margin: 16px -16px -16px;
        background: var(
            --sys-color-light-surface-container,
            rgba(238, 240, 242, 1)
        );
        border: none;
        padding: 16px;

        & > div {
            background-color: white;
            padding: 16px;
            border-radius: 8px;
        }
    }
}
